<template>
  <div>
    <div class="main">
      <div class="main__header">
        <div class="main__logo">
          <img
            src="@/assets/images/homeImg/logoHeader.png"
            alt
            class="main__logo-img"
          />
        </div>
      </div>
      <div class="main__form" v-show="isCode">
        <div class="main__form-cut" @click="cutFun" v-show="false">
          <img
            class="main__form-icon"
            src="@/assets/images/login/cut-qrcode.png"
          />
        </div>
        <!-- <div class="main__form-title">手机登录</div> -->
        <div class="main__form-method">
          <div
            class="title"
            v-for="(item, index) in tabArr"
            :key="index"
            :class="{ active: selIndex == index }"
            @click="tabClick(index)"
          >
            {{ item }}
          </div>
        </div>
        <a-form-model
          ref="form"
          :model="form"
          @submit="handleSubmit"
          :rules="selIndex == 1 ? rules : rulesTwo"
        >
          <a-form-model-item prop="mobile">
            <a-input
              size="large"
              v-model="form.mobile"
              placeholder="请输入手机号"
              :maxLength="11"
            >
              <a-icon
                slot="prefix"
                type="mobile"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="code" v-if="selIndex == 1">
            <a-row>
              <a-col :span="16">
                <a-input
                  size="large"
                  v-model="form.code"
                  placeholder="请输入验证码"
                  :maxLength="6"
                >
                  <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0, 0, 0, 0.25)"
                  />
                </a-input>
              </a-col>
              <a-col :span="8">
                <a-button
                  block
                  size="large"
                  @click="getCode"
                  style="color: #376cde; font-size: 14px"
                  >{{ btnText }}</a-button
                >
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item prop="password" v-if="selIndex == 0">
            <a-row>
              <a-input-password
                size="large"
                v-model="form.password"
                placeholder="请输入密码"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input-password>
            </a-row>
          </a-form-model-item>
          <a-form-model-item class="margin_bottom_none">
            <a-button
              block
              size="large"
              shape="round"
              type="primary"
              :loading="loading"
              html-type="submit"
              >登录</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <div class="main__form-forget" @click="toAccount">忘记密码？</div>
        <div class="main__form-text main__form-text_ab">
          亲爱的仟仕云伙伴，请开启你的学习之旅
        </div>
      </div>

      <div class="main__form" v-show="!isCode">
        <div class="main__form-cut" @click="cutFun">
          <img
            class="main__form-icon"
            src="@/assets/images/login/cut-form.png"
          />
        </div>
        <loading :loading="codeLoading" />
        <div class="main__form-title main__form-title_mb" style="opacity: 0">
          扫码登录
        </div>
        <div class="main__form-text main__form-text_color" style="opacity: 0">
          请使用【企业微信】扫码登陆
        </div>
        <div class="main__form-codebox" id="weChat"></div>
        <!-- <iframe scrolling="no" :src="url" width="360px" height="340px" style="border: medium none;"></iframe> -->
        <!-- <div class="main__form-title main__form-title_mb">扫码登录</div>
        <div class="main__form-text main__form-text_color">请使用【云之家】扫码登陆</div>
        <div class="main__form-codebox">
          
        </div>-->
        <!-- <div class="main__form-text main__form-text_ab">亲爱的仟仕云伙伴，请开启你的学习之旅</div> -->
      </div>
    </div>
    <footer-nav />
  </div>
</template>
<script>
import footerNav from "@/components/footer-nav";
import loading from "@/components/loading";
import { getCode, getPCQrconnetUrl } from "@/api/user";
// import { action } from '@/store'
// 手机号表单验证
let validateMonile = (rule, value, callback) => {
  //11位手机号码正则表达式
  let reg =
    /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
  if (value === "") {
    callback(new Error("手机号不能为空!"));
  } else if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号!"));
  } else {
    callback();
  }
};

// 验证码的验证
let validateCode = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("验证码不能为空!"));
  } else {
    callback();
  }
};

// 密码验证
let validatePsd = (rule, value, callback) => {
  // let reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
  if (value === "") {
    callback(new Error("密码不能为空!"));
  }
  //  else if (!reg.test(value) && value.length < 8) {
  //   callback(new Error("密码格式不对"));
  // } 
  else {
    callback();
  }
};

const rules = {
  mobile: [
    {
      validator: validateMonile,
    },
  ],
  code: [
    {
      validator: validateCode,
    },
  ],
};
const rulesTwo = {
  mobile: [
    {
      validator: validateMonile,
    },
  ],
  password: [
    {
      validator: validatePsd,
    },
  ],
};

export default {
  components: { footerNav, loading },
  data() {
    return {
      tabArr: ["账号登录", "验证码登录"],
      selIndex: 0,
      isCode: true,
      loading: false,
      form: {
        mobile: "",
        code: "", // 通用验证码,调试用
        password: "",
      },
      rules,
      rulesTwo,
      btnText: "获取验证码",
      timer: null,
      url: "",
      bool: null,
      codeLoading: false,
    };
  },
  created() {},
  mounted() {
    // this.getHref();
  },
  methods: {
    cutFun() {
      this.isCode = !this.isCode;
      if (this.isCode === false) {
        // this.getPCQrconnetUrl();
        this.getWeChat();
      }
      // console.log(ticket);
    },
    tabClick(tag) {
      this.selIndex = tag;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.loading = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.selIndex == 1) {
            this.$store
              .dispatch("user/mobileLogin", this.form)
              .then(() => {
                this.$message.loading({
                  content: "登录中...",
                  key: "updatable",
                });
                setTimeout(() => {
                  this.$message.success({
                    content: "登录成功!",
                    key: "updatable",
                    duration: 2,
                  });
                  this.$router.push({
                    name: "home",
                  });
                }, 500);
              })
              .catch((err) => {
                this.$message.error(err);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.$store
              .dispatch("user/accountLogin", this.form)
              .then(() => {
                this.$message.loading({
                  content: "登录中...",
                  key: "updatable",
                });
                setTimeout(() => {
                  this.$message.success({
                    content: "登陆成功!",
                    key: "updatable",
                    duration: 2,
                  });
                  this.$router.push({
                    name: "home",
                  });
                }, 1000);
              })
              .catch((err) => {
                this.$message.error(err);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    // 获取验证码
    getCode() {
      if (this.btnText > 0) return false;
      this.$refs.form.validateField("mobile", (value) => {
        if (value === "") {
          this.btnText = 60;
          getCode({
            smsType: 0,
            telephone: this.form.mobile,
          }).then((res) => {
            if (res.success === true) {
              this.timer = setInterval(() => {
                if (this.btnText <= 0) {
                  clearInterval(this.timer);
                  this.timer = null;
                  this.btnText = "重新获取";
                  return;
                }
                this.btnText--;
              }, 1000);
            }
          });
        } else {
          this.$message.warning("手机号不能为空");
        }
      });
    },
    getWeChat() {
      this.codeLoading = true;
      // 动态引入企业微信js文件
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src =
        "http://rescdn.qqmail.com/node/ww/wwopenmng/js/sso/wwLogin-1.0.0.js";
      const wxElement = document.body.appendChild(s);
      // 调用企业微信二维码方法
      wxElement.onload = () => {
        window.WwLogin({
          id: "weChat", // 需要显示的容器id
          appid: "wwf6adae3e78c08e79", // 公众号appid wx*******
          agentid: "1000002", // 公众号agentid wx*******
          redirect_uri: encodeURIComponent("http://pc.qianshicloud.com/home"), // 授权成功后回调的url，需要在企业微信配置
          state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
          style: "black", // 提供"black"、"white"可选。二维码的样式
          href: "",
        });
        var dom = document.getElementsByTagName("iframe")[0];
        dom.style.position = "relative";
        dom.style.top = "-110px";
        dom.style.left = "-50px";
        dom.style.transform = "scale(0.9)";
        this.codeLoading = false;
      };
    },

    getPCQrconnetUrl() {
      getPCQrconnetUrl({})
        .then((res) => {
          this.url = res.result;
        })
        .finally(() => {
          console.log(1213);
        });
    },
    toAccount() {
      this.$router.push({
        name: "account",
      });
    },
  },
};
</script>
 
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 827px;
  background: url("~@/assets/images/login/login-bg.png") no-repeat;
  position: relative;
  background-size: auto 100%;
  &__header {
    border-bottom: 2px solid #FFFFFF;
  }
  &__logo {
    width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    &-img {
      margin-right: 6px;
      width: 197px;
    }
    &-text {
      font-size: 28px;
      font-weight: bold;
      color: #c4a468;
    }
    &-title {
      font-size: 28px;
      color: #c4a468;
      font-weight: 600;
    }
  }
  &__form {
    width: 360px;
    height: 444px;
    position: absolute;
    top: 223px;
    right: 387px;
    background: #fff;
    border-radius: 6px;
    &-cut {
      width: 64px;
      height: 64px;
      position: absolute;
      top: 0;
      right: 0;
      background: url("~@/assets/images/login/cut-bg.png") 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    &-title {
      font-size: 30px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #7f7f7f;
      line-height: 36px;
      text-align: center;
      margin: 37px auto 31px;
      // background: linear-gradient(124deg, #c4a468 0%, #9b7836 100%);
      background: linear-gradient(124deg, #c4a468 0%, #9b7836 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &_mb {
        margin: 37px auto 16px;
      }
    }
    &-method {
      display: flex;
      justify-content: space-between;
      padding: 0 48px;
      padding-bottom: 10px;
      margin-top: 40px;
      margin-bottom: 40px;
      .title {
        font-size: 16px;
        color: #999999;
        cursor: pointer;
        &.active {
          position: relative;
          background: #5DB4FF;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: flex;
          padding-bottom: 14px;
        }

        &.active::after {
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          position: absolute;
          content: "";
          width: 64px;
          height: 3px;
          background-color: #5DB4FF;
        }
      }
    }
    &-text {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      &_ab {
        position: absolute;
        left: 0;
        bottom: 29px;
      }
      &_color {
        color: #999999;
      }
    }
    &-codebox {
      width: 200px;
      height: 202px;
      margin: 22px auto 30px;
      border: 1px dashed rgba($color: #000000, $alpha: 0.6);
    }
    &-qrcode {
      width: 100%;
    }
    &-forget {
      color: #999999;
      display: inline-block;
      float: right;
      margin-right: 30px;
      margin-bottom: 30px;
      margin-top: 10px;
      cursor: pointer;
    }
    .iframe {
      width: 200px;
      height: 200px;
    }
  }
  .margin_bottom_none {
    margin-bottom: 0;
  }
}

::v-deep.ant-form {
  width: 300px;
  margin: 0 auto;
}

::v-deep.ant-form-item {
  margin-bottom: 40px;
}
/* 二维码外框大小 */
.wrp_code {
  // width: 100px;
  // height: 100px;
  width: 1px;
  height: 1px;
}
/*二维码图片大小*/
.impowerBox .qrcode {
  width: 200px !important;
  height: 200px !important;
  margin-top: -60px !important;
  margin-left: -60px !important;
}
/*背景颜色*/
body {
  background-color: rgb(22, 51, 51) !important;
}
.title {
  display: hidden;
}
::v-deep .ant-btn-primary{
  background-color: #5DB4FF;
  border-color: #5DB4FF;
}
</style>